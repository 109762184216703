import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  controlLabel: {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
