import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { paths } from 'entities/config'

import {
  AccountPage,
  DashboardPage,
  HomePage,
  LoaderPage,
  SignInPage,
  SignUpPage,
  ThemesPage,
} from 'pages/components'

import { AuthLayout, DashboardLayout, RootLayout } from 'app/layout'

import { RouteGuard } from '../RouteGuard'

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route
        path={paths.home}
        element={
          <RouteGuard>
            <HomePage />
          </RouteGuard>
        }
      />
      <Route
        path={paths.loader}
        element={
          <RouteGuard>
            <LoaderPage />
          </RouteGuard>
        }
      />
      <Route element={<DashboardLayout />}>
        <Route
          path={paths.dashboard}
          element={
            <RouteGuard privateRoute>
              <DashboardPage />
            </RouteGuard>
          }
        />
        <Route
          path={paths.account}
          element={
            <RouteGuard privateRoute>
              <AccountPage />
            </RouteGuard>
          }
        />
        <Route
          path={paths.themes}
          element={
            <RouteGuard privateRoute>
              <ThemesPage />
            </RouteGuard>
          }
        />
      </Route>
      <Route element={<AuthLayout />}>
        <Route
          path={paths.signIn}
          element={
            <RouteGuard authRoute>
              <SignInPage />
            </RouteGuard>
          }
        />
        <Route
          path={paths.signUp}
          element={
            <RouteGuard authRoute>
              <SignUpPage />
            </RouteGuard>
          }
        />
      </Route>
    </Route>
  )
)

export default Router
