import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

export function RootLayout() {
  return (
    <Box>
      <Outlet />
    </Box>
  )
}
