import { StylesClasses, createStyles } from 'shared/utils'

import { LayoutBg } from 'app/assets'

const styles = createStyles({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'relative',
  },
  createWithLogo: {
    fontWeight: '600',
    position: 'absolute',
    left: '20px',
    top: '15px',
    color: 'common.white',
    fontSize: '25px',
    filter: 'invert(1)',
  },
  leftSection: {
    typography: 'center',
    flexBasis: '60%',
    backgroundImage: `url(${LayoutBg})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightSection: {
    flexBasis: '40%',
    backgroundColor: 'common.white',
  },
  authContent: {
    width: '70%',
    height: '80%',
    backgroundColor: 'custom.glass',
    backdropFilter: 'blur( 4px )',
    WebkitBackdropFilter: 'blur( 6.5px )',
    borderRadius: '10px',
    padding: '50px',
  },
  addressOfferVector: {
    position: 'relative',
    top: '35px',
    left: '-35px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  textWrapper: {
    maxWidth: 'max(500px,70%)',
    color: 'common.white',
  },
  heading: {
    fontSize: '30px',
    marginBlock: '0',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
