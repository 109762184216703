export const defaultEn = {
  signInTitle: 'Login',
  signUpTitle: 'Get Started',
  authSubtitle:
    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse, ab.',
  forgotPassword: 'Forgot Password?',
  orText: 'or',
  signInLabel: {
    email: 'Email Address',
    password: 'Password',
  },
  signInPlaceholder: {
    email: 'Enter Email Address',
    password: 'Enter Password',
  },
  signUpLabel: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    country: 'Country',
  },
  signUpPlaceholder: {
    firstName: 'Enter First Name',
    lastName: 'Enter Last Name',
    email: 'Enter Email Address',
    password: 'Enter Password',
    confirmPassword: 'Re-enter Password',
    country: 'Select Country',
  },
  themeSettingsLabel: {
    inventory: 'Inventory',
    productCard: 'Product Card',
    help: 'Help',
    share: 'Share',
    productDisplayPage: 'Product Display Page',
    searchPage: 'Search Page',
    menuConfig: 'Menu Config',
  },
}
