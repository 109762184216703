import { ComponentProps } from 'react'

import { Button } from '@mui/material'

type SimpleButtonProps = ComponentProps<typeof Button> & {
  label?: string
  children?: React.ReactNode
}

// Button - can be used as link button
export function SimpleButton({
  label,
  variant = 'contained',
  children,
  ...rest
}: SimpleButtonProps) {
  return (
    <Button variant={variant} aria-label={label} {...rest}>
      {label || children}
    </Button>
  )
}
