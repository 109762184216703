import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '30%',
    minWidth: '30%',
    backgroundColor: 'common.white',
    borderRadius: '5px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.border.light',
    padding: '24px 24px 16px',
  },
  headerLeftContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
  },
  headerRightContent: {},
  title: {
    fontWeight: '600',
    fontSize: '16px',
  },
  desc: {
    color: 'custom.text.light',
    fontSize: '14px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  children: {
    padding: '24px',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
