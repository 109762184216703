import { useQuery, useQueryClient } from '@tanstack/react-query'

import { storage } from 'shared/utils'

import { AuthResponse } from 'entities/model/auth/auth.model'

export const UserSessionKey = ['user-session-key']

export const UserKey = 'user'

export type UserData = AuthResponse['data']

export function useUserSession() {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: UserSessionKey,
    queryFn: () => {
      const userData = queryClient.getQueryData<UserData>(UserSessionKey)

      if (userData) {
        return userData
      }

      const userDataLocal = storage.getData(UserKey)
      if (userDataLocal) {
        return userDataLocal as UserData
      }

      return {
        id: '',
        entityId: '',
        firstName: '',
        country: '',
        email: '',
      }
    },
  })

  return query
}
