import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    '.MuiInputBase-root': {
      border: '0',
      borderRadius: '8px',
    },
    '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
      {
        borderColor: 'custom.inputBorder',
        borderWidth: '1px !important',
      },
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
