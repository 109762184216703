import { ReactNode } from 'react'

import { Box, ButtonProps, Button as MuiButton } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StyleClassNames } from './styles'

type ComponentType = 'Link' | 'button' // Define available component types

type GradientButtonProps<T extends ComponentType> = {
  label: string
  customStyles?: CustomStyles<StyleClassNames>
  component: T
  children?: ReactNode
} & (T extends 'Link'
  ? { to: string }
  : T extends 'button'
    ? ButtonProps
    : never)

export function GradientButton<T extends ComponentType>({
  label,
  component,
  children,
  customStyles,
  ...rest
}: GradientButtonProps<T>) {
  const styles = getStyles(defaultStyles, customStyles)

  const Component = component === 'Link' ? RouterLink : MuiButton

  return (
    <Box component={Component} {...styles('gradientBtn')} {...rest}>
      {children ?? label}
    </Box>
  )
}
