import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { getStyles } from 'shared/utils'

import defaultStyles from './styles'

type OptionType = {
  value: string
  label: string
}

type RadioButtonGroupType = { options: OptionType[] }

export function RadioButtonGroup({ options }: RadioButtonGroupType) {
  const styles = getStyles(defaultStyles)

  return (
    <FormControl size="small">
      <FormLabel id="radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              color="primary.main"
              label={option.label}
              {...styles('controlLabel')}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
