export const defaultEn = {
  navLinkTitles: {
    screens: 'Screens',
    themes: 'Themes',
    branding: 'Branding',
    notifications: 'Notifications',
    plugins: 'Plugins',
    deeplink: 'Deeplink',
    account: 'Account',
    settings: 'Settings',
  },
  commonHeader: {
    greeting: 'Welcome',
    launchLabel: 'Launch',
  },
  loginWithShopify: 'Login With Shopify',
}
