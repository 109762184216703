import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    paddingTop: '58px',
    backgroundColor: 'custom.background.light',
  },
  childWrapper: {
    display: 'flex',
    paddingLeft: 'calc(90px + 20px)',
  },
  headerWrapper: {
    display: 'flex',
    height: '58px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'common.white',
    paddingInline: '20px',
    borderBottom: '1px solid',
    borderColor: 'custom.border.main',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '2',
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  createWithLogo: {
    paddingInline: '15px',
    borderRight: '1px solid',
    borderColor: 'custom.border.main',
    borderWidth: '1px',
    cursor: 'pointer',
  },
  greetings: {
    fontSize: '0.9rem',
    fontWeight: 'lighter',
    marginLeft: '15px',
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  profileIcon: {
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: 'custom.border.main',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navbar: {
    width: '90px',
    padding: '24px 10px',
    backgroundColor: 'primary.main',
    minHeight: 'calc(100vh - 58px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
    position: 'fixed',
    left: '0',
    zIndex: '2',
    top: '58px',
  },

  navLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    borderRadius: '4px',
    width: '100%',
    paddingBottom: '3px',
    '&:hover': {
      backgroundColor: 'custom.navLinkHover',
    },
    '&:last-child': {
      marginTop: 'auto',
    },
  },

  navLinkIcon: {
    borderRadius: '4px',
    width: '45px',
    height: '44px',
  },

  navLinkActive: {
    background: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    filter: 'brightness(150%)',
    borderRadius: '4px',
    width: '45px',
    height: '44px',
  },

  navLinkTitle: {
    fontSize: '12px',
    color: 'custom.navLink',
    marginBottom: '7px',
    textTransform: 'capitalize',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
