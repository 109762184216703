export const paths = {
  home: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  dashboard: '/dashboard',
  account: '/account',
  screens: '/screens',
  themes: '/themes',
  branding: '/branding',
  notifications: '/notifications',
  deeplink: '/deeplink',
  plugins: '/plugins',
  loader: '/loader',
}
