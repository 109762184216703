import { Box, ListItem, Typography } from '@mui/material'
import { Link, Outlet, useLocation } from 'react-router-dom'

import { GradientButton } from 'shared/components'
import { getStyles } from 'shared/utils'

import {
  AddIcon,
  CreateWithLogo,
  SettingsIcon,
  ShopifyLogo,
} from 'entities/assets'
import { navLinks } from 'entities/config'
import { useEntitiesTranslation } from 'entities/locale'

import defaultStyles from './styles'

export function DashboardLayout() {
  const { tEntities } = useEntitiesTranslation()
  const location = useLocation()

  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box component="header" {...styles('headerWrapper')}>
        <Box {...styles('leftSection')}>
          <Box
            component="img"
            src={AddIcon}
            alt="add-icon"
            height="32px"
            sx={{ cursor: 'pointer' }}
          />
          <Box
            component="img"
            src={CreateWithLogo}
            alt="logo"
            height="22px"
            {...styles('createWithLogo')}
          />
          <Box className="center" {...styles('greetings')}>
            <Box component="span">{tEntities('commonHeader.greeting')},</Box>
            <Box component="span" {...styles('userName')}>
              &nbsp;David Miller
            </Box>
          </Box>
        </Box>
        <Box {...styles('rightSection')}>
          <Box component="span" {...styles('profileIcon')}>
            <Box
              component="img"
              src={ShopifyLogo}
              sx={{ objectFit: 'cover' }}
              width="33px"
              height="33px"
            />
          </Box>
          <GradientButton
            label={tEntities('commonHeader.launchLabel')}
            component="button"
          />
        </Box>
      </Box>{' '}
      <Box {...styles('childWrapper')}>
        <Box component="nav" {...styles('navbar')}>
          {navLinks.map(({ icon, tKey, href }) => {
            return (
              <ListItem
                {...styles('navLink')}
                component={Link}
                to={href}
                key={tKey}
              >
                <Box
                  component="img"
                  src={icon}
                  alt={tKey}
                  {...styles([
                    location.pathname === href
                      ? 'navLinkActive'
                      : 'navLinkIcon',
                  ])}
                />
                <Typography {...styles('navLinkTitle')} variant="inherit">
                  {tEntities(`navLinkTitles.${tKey}`)}
                </Typography>
              </ListItem>
            )
          })}
          <Box {...styles('navLink')} component={Link} to="/dashboard">
            <Box component="img" src={SettingsIcon} alt="settings" />
            <Typography {...styles('navLinkTitle')} variant="inherit">
              {tEntities('navLinkTitles.settings')}
            </Typography>
          </Box>
        </Box>
        <Outlet />
      </Box>
    </Box>
  )
}
