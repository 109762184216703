import { PaletteOptions } from '@mui/material/styles'

// Configure all the required colors palettes here
export const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#0D0E10',
  },
  error: {
    main: '#f44336',
  },
  secondary: {
    main: '#F0F1F2',
  },
  success: {
    main: '#30B699',
  },
  text: {
    primary: '#272727',
    secondary: '#757575',
  },
  common: {
    white: '#ffffff',
    black: '#000000',
  },

  surface: {
    main: '',
  },

  // Custom palette
  custom: {
    main: '#f5f5f5',
    thumb: '#bbbec2',
    track: '#e8e9eb',
    glass: '#ffffff33',
    navLink: '#FDFDFD',
    navLinkHover: '#424752',
    lightBg: '#F5F4FD',
    text: {
      main: '',
      light: '#616A7A',
    },
    border: {
      main: '#DFDEEB',
      dark: '',
      light: '#EBECF4',
    },
    background: {
      main: '',
      dark: '',
      light: '#F5F4FD',
    },
  },
}
