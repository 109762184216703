import { Suspense } from 'react'

import { Loader } from 'shared/components'

import {
  QueryProvider,
  RouterProvider,
  ThemeProvider,
  I18nProvider,
} from './providers'
import './App.css'

function App() {
  return (
    <I18nProvider>
      <QueryProvider>
        <ThemeProvider>
          <Suspense fallback={<Loader fullView loading />}>
            <RouterProvider />
          </Suspense>
        </ThemeProvider>
      </QueryProvider>
    </I18nProvider>
  )
}

export default App
