import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { getStyles } from 'shared/utils'

import { CreateWithLogo } from 'entities/assets'

import { AddressCard, MobileVector, OfferCard, Sparkle } from 'app/assets'
import { useAppTranslation } from 'app/locale'

import defaultStyles from './styles'

export function AuthLayout() {
  const { tApp } = useAppTranslation()

  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('createWithLogo')}>
        <Box component="img" src={CreateWithLogo} height="20px" />
      </Box>
      <Box {...styles('leftSection')}>
        <Box {...styles('authContent')}>
          <Box>
            <Box component="div" className="center">
              <Box
                component="img"
                src={MobileVector}
                alt="mobile-vector"
                height="400px"
              />
              <Box component="div" {...styles('addressOfferVector')}>
                <Box component="img" src={AddressCard} alt="address" />
                <Box component="img" src={OfferCard} alt="offer" width="90%" />
              </Box>
            </Box>
            <Box component="img" src={Sparkle} alt="sparkle" height="44px" />
          </Box>
          <Box component="div" {...styles('textWrapper')}>
            <Box component="h2" {...styles('heading')}>
              {tApp('heading')}
            </Box>
            <Box component="p">{tApp('subheading')}</Box>
          </Box>
        </Box>
      </Box>
      <Box {...styles('rightSection')}>
        <Outlet />
      </Box>
    </Box>
  )
}
